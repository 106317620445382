import React from 'react';

const SignUp1 = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));
const LandingPage = React.lazy(() => import('./Demo/Authentication/LandingPage'));
const Shipment = React.lazy(() => import('./Shipment'));

const route = [
    { path: '/signup', exact: true, name: 'Signup 1', component: SignUp1 },
    { path: '/login', exact: true, name: 'Signin 1', component: Signin1},
    { path: '/shipment', exact: true, name: 'Shipment', component: Shipment },
    { path: '/', exact: true, name: 'LandingPage', component: LandingPage }
];

export default route;